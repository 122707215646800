/* ---------------------------------------------------------------
PHOTO GALLERY
----------------------------------------------------------------*/
.gallery-photos {
	margin: 2rem auto 0;
	padding: 0;
    
    li {
        position: relative;
		background-image: none;
		padding: 0;
        margin: 0;
		text-align: center;
        
        a {
            display: block;
            position: relative;
            width: 100%;
            overflow: hidden;
            transform-style: preserve-3d;            
            
            img {
                position: absolute;
                min-width: 150%;
                top: 10%;
                left: 50%;
                transform: translate(-50%,-10%); 
            }
            
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                opacity: 0;
                background-color: rgba(255,255,255,.4);
                transition: opacity .3s ease;
                z-index: 999;
            }
        }
            
        a:hover .overlay {
            opacity: 1;
        }
        a:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }
}
/*! ---------------------------------------------------------------
PRINT
----------------------------------------------------------------*/
@media print {
    .talent-photos {
        page-break-before: always;
    }
    .gallery-photos.grid5 > li {
        width: 33%;
    }
   .gallery-photos li {
       padding: 12px;
       height: 292px;
       overflow: hidden;
       
        a {
            display: block;
            position: relative;
            width: 100%;
            overflow: hidden;
        
        img {
            position: relative;
            min-width: 1px;
            height: auto;
            top: auto;
            left: auto;
            transform: none; 
        }
    }
   }
    .gallery-photos li a:after {
        display: none;
    }
}