/* ---------------------------------------------------------------
BREADCRUMB MENU
----------------------------------------------------------------*/
.breadcrumb {
	font-size: .75em;
	font-weight: normal;
	list-style-type: none;
	margin: 1em 0 1.5em;
	overflow: hidden;
    padding: 0;
}
.breadcrumb li {
    background: none;
    float: left;
    line-height: normal;
    margin: 0;
    padding: 0;
}
.breadcrumb li:first-child {
    padding-left: 0;
}
.breadcrumb li a {
    color: $link-color;
    text-decoration: underline;
}
.breadcrumb li a:hover {
    color: $link-color-hover;
    text-decoration: none;
}
.breadcrumb li a:after {
    content: '\f101';
    display: inline-block;
    font-family: FontAwesome;
    padding-left: .5em;
    padding-right: .5em;
}