/* ---------------------------------------------------------------
CARDS
----------------------------------------------------------------*/
.card {
    position: relative;
	margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    overflow: hidden;
}
.card-image {
    position: relative;
    height: 180px;
    overflow: hidden;
    -webkit-transform-style: preserve-3d;
       -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;
}
.card-image img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    margin-bottom: 0;
}
.card-image-caption {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 15px;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    text-shadow: 1px 1px 2px rgba(0,0,0,.8);
}
.card-content {
    padding: 1rem;
}
.card-content p {
    font-size: .875em;
}
.card-actions {
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 1rem;
    padding: .75rem 1rem;
    border-top: 1px solid $secondary-border-color;
}
.card-actions a {
    margin-right: 1rem;
    color: $link-color;
    text-transform: uppercase;
}
.card-actions a:hover {
    color: $link-color-hover;
}
