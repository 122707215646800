/* ---------------------------------------------------------------
POSTINGS
----------------------------------------------------------------*/
/* FULL POSTING PREVIEW */
.post-preview {
	border-bottom: 1px solid $secondary-border-color;
	margin-top: $baseline;
	padding-bottom: $baseline;
	overflow: hidden;
}
	.post-preview-thumb {
        float: left;
		margin-right: 20px;
		width: 144px;
        height: 144px;
        overflow: hidden;
        -webkit-transform-style: preserve-3d;
           -moz-transform-style: preserve-3d;
                transform-style: preserve-3d;
        /*padding: 4px;
        border: 1px solid #4E4E4E;
        background: #eaeaea;*/
	}
	/*.post-preview-thumb-frame {
        overflow: hidden;
		width: 134px;
        height: 134px;
	}*/
	.post-preview-thumb img {
        position: relative;
        max-width: none;
        max-height: 144px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .post-preview-content {
        overflow: hidden;
    }
	.post-preview h2,
	.post-preview h3 {
		margin-top: 0;
		margin-bottom: .25em;
	}
	.post-preview h2 a,
	.post-preview h3 a {
		text-decoration: none;
	}
	.post-preview p {
		margin-bottom: 1em;
	}
	.post-preview p.post-preview-pub-date {
		color: #888;
		font-size: $base-font-size * .85;
		line-height: 1em;
		text-transform: uppercase;
		margin-bottom: .25em;
	}
    
/* HOME PAGE POSTING PREVIEW */
.post-preview-home {
    margin-top: 0;
    border-bottom: none;
}
    .post-preview-home a {
        display: block;
        text-decoration: none;
    }
    .post-preview-home img {
        margin-bottom: 6px;
    }
    .post-preview-home h3 {
        font-size: 18px;
    }
    .post-preview-home a:hover h3 {
        color: $title-color;
    }
    .post-preview-home a:hover img {
        opacity: .8;
    }   
    
/* POSTING PREVIEW - HEADLINE ONLY */
.post-preview-home {
    margin-top: 0;
    border-bottom: none;
}

/* POSTING AUTHOR INFO */
.author {
    border: 1px solid $primary-border-color;
    padding: 1.5em;
}
.author-thumb {
    width: 20%;
    margin-right: 1.5em;
    float: left;
}
.author-info {
    overflow: hidden;
}
.author-info h4 {
    margin-top: 0;
}
.author-info p {
    font-size: .875em;
}