/* ---------------------------------------------------------------
	SITE-SPECIFIC THEME STYLES
----------------------------------------------------------------*/
/* FONTS */
$primary-font: 'Open Sans', Verdana, sans-serif;
$secondary-font: 'Roboto Slab', serif;

/* FONT SIZING */
$base-font-size: 1em;
$font-scale: 1.25;
$h1-size: 2.625em;
$h2-size: $h1-size / $font-scale;
$h3-size: $h2-size / $font-scale;
$h4-size: $h3-size / $font-scale;
$h5-size: $h4-size / $font-scale;
$h6-size: $h5-size / $font-scale;

/* MEASUREMENTS */
$baseline: 24px;
$page-width: 1152px; /* 1920,1680,1280,1152,1024,800 */
$page-gutter: 24px;
$page-max-width: $page-width + $page-gutter;
$col-padding: $baseline / 2 $page-gutter;
$float-lr: $baseline / 2;
$float-bottom: 12$baseline / 2px;
$header-height: 0; /* use for fixed header */
$footer-height: 0; /* use for sticky footer */

/* COLORS */
$default-color: #222;
$primary-color: #a30d0d;
$secondary-color: #888;
$title-color: $primary-color;
$title-border-color: #d5cfc4;
$link-color: $primary-color;
$link-color-hover: darken( $primary-color, 10% );
$link-color-footer: #fff;
$link-color-footer-hover: #fff;
$button-color: $primary-color;
$button-color-hover: #740808;
$primary-border-color: #d5cfc4;
$secondary-border-color: #e1e1e1;
$primary-bg-color: #65645f;
$secondary-bg-color: #d5cfc4;
$alt-bg-color: #f4f4f4;
$table-header-color: #fff;
$table-header-bg-color: #000;
$success-color: #008417;
$critical-color: #f00;

body {
    font-family: $primary-font;
    font-size: $base-font-size;
	line-height: 1.5;
	color: $default-color;
}
body#home {
    background: #eeebe6;
}


/*! ---------------------------------------------------------------
PAGE LAYOUT
----------------------------------------------------------------*/
.row {
	max-width: $page-width;
}

.row .row {
	margin-left: -$page-gutter;
	margin-right: -$page-gutter;
    max-width: $page-width + $page-gutter; /* .row max-width + (margin-left + margin-right) */
}

.column {
    padding: $col-padding;
}
.column-right {
    float: right;
}
.gutter-10 {
    margin-right: -10px;
    margin-left: -10px;
}
.gutter-10 .column {
    padding-right: 10px;
    padding-left: 10px;
}

/* Page content wrapper */
#wrapper {
	width: 100%;
	position: relative;
    margin: 0 auto;
}

/* Main content container */
#main-content {
	padding-top: $baseline;
	padding-bottom: $baseline;
}

.float-left {
	margin-right: $float-lr;
	margin-bottom: $float-bottom;
}
.float-right {
	margin-left: $float-lr;
	margin-bottom: $float-bottom;
}

.show-sm,
.show-med {
    display: none;
}

.content-container {
	overflow: hidden;
}

/*! ---------------------------------------------------------------
TYPOGRAPHY
----------------------------------------------------------------*/
/* Secondary font face */
h1,h2,h3,h4,h5,h6 {
	font-family: $secondary-font;
}

p {
	margin-bottom: 1.5em;
}
	p:last-child {
		margin-bottom: 0; /* Remove bottom margin from last paragraph */
	}
ul,
ol {
	margin: 0 0 1.5em;
    list-style: none;
}
    ul ul,
    ol ol {
        margin-bottom: 0;
    }
	ul li,
	ol li {
		margin: 0 0 .75em;
        padding-left: 1.5em;
	}
	ul li {
		background: url(../images/bullet.png) no-repeat 0 5px;
	}
	ol li {
        list-style: decimal;
	}
	ul.square,
	ul.circle,
	ul.disc {
		margin-left: 2.5em;
	}
	ul.square li,
	ul.circle li,
	ul.disc li {
		background-image: none;
		padding-left: .25em;
	}
	li li {
		font-size: inherit;
	}
    ol ol {
        list-style-type: lower-alpha;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }
    .no-style {
        list-style-type:none;
        margin:0 0 20px;
    }
    .no-style.column {
        padding: 0;
    }
    .no-style li {
        margin:0 0 5px;
        padding-left: 0;
        background-image: none;
    }

h1,h2,h3,h4,h5,h6 {
	color: $primary-color;
	line-height: 1.125;
    font-weight: 400;
    margin-top: 1em;
    margin-bottom: .5em;
	letter-spacing: -.05em;
}
	h1,
    .h1 {
		font-size: $h1-size;
        margin-top: 0;
		margin-bottom: .5em;
	}
	h2,
    .h2 {
		font-size: $h2-size;
	}
	h3,
    .h3,
	aside h2 {
		font-size: $h3-size;
	}
	h4,
    .h4,
	aside h3 {
		font-size: $h4-size;
	}
	h5,
    .h5,
	aside h4 {
		font-size: $h5-size;
	}
	h6,
    .h6,
	aside h5 {
		font-size: $h6-size;
	}

	/* Subtitles */
    .subtitle {
        color: #666;
    }
	h1 + h2.subtitle,
	h2 + h3.subtitle,
	h3 + h4.subtitle {
		margin-top: -.25em;
	}

    /* Small title text */
    h1 .small,
    .h1 .small,
    h1 small,
    .h1 small,
    h2 .small,
    .h2 .small,
    h2 small,
    .h2 small,
    h3 .small,
    .h3 .small,
    h3 small,
    .h3 small,
    h4 .small,
    .h4 .small,
    h4 small,
    .h4 small {
        font-size: 60%;
    }

/* Links */
a {
	color: $link-color;
	text-decoration: none;
    transition: color .3s ease;
}
a:hover {
    color: $link-color-hover;
	text-decoration: underline;
}

/* Abbreviations */
abbr,
acronym {
	border-bottom: 1px dotted #ddd;
	color: $link-color;
}

/* Block quote */
blockquote {
	background-color: #ecede7;
	border-left: 5px solid $primary-border-color;
	margin: $baseline;
	padding: $baseline / 2;
}
	blockquote p {
		font-style: italic;
		margin-top: 0;
	}
	blockquote p.cite {
		color: #555;
		display: block;
		font-size: 1em;
		margin-top: -1em;
        font-style: normal;
	}
	blockquote p.cite:before {
		content: "\2014 \0020";
	}

/*! ---------------------------------------------------------------
TABLES
----------------------------------------------------------------*/
table {
	border: none;
	margin: 1.5em 0 1.5em;
}
.striped tbody tr:nth-child(odd) {
	background: $alt-bg-color;
}
th,td {
	border-bottom: 1px solid $primary-border-color;
	padding: .5em;
	text-align: left;
}
th {
	font-weight: bold;
    background: $table-header-bg-color;
    color: $table-header-color;
}

/*! ---------------------------------------------------------------
FORMS
----------------------------------------------------------------*/
form {
	margin: 0;
}
fieldset {
	border: 1px solid $primary-border-color;
	margin-bottom: $baseline / 2;
	padding: $baseline;
}
    #account fieldset p {
        margin-bottom: .5em;
    }
.row fieldset .row {
	margin-left: -$page-gutter;
	margin-right: -$page-gutter;
}
fieldset h2 {
	background-color: $primary-bg-color;
	color: #fff;
	font-size: 24px;
}
fieldset .row:last-of-type .column {
	margin-bottom: 0;
}
.form-group {
    margin-bottom: 1em;
}
fieldset .form-group:last-child,
fieldset ul:last-child {
    margin-bottom: 0;
}
.row .form-group.row {
    margin-right: 0;
    margin-left: 0;
}
label,
span.label {
	color: $primary-color;
	font-weight: 700;
	margin-bottom: .3333em;
}
span.label {
    display: inline-block;
    width: 124px;
    margin-right: .5rem;
}
span.label-no-width {
    width: auto;
}
/*label.label-inline {
    padding-top: 7px;
}*/
label > .small {
    /*font-weight: normal;*/
    display: inline-block;
}

#searchForm label:not(.label-rcb) {
    text-align: right;
    padding-top: 6px;
}
#searchForm .column {
    padding-right: 0;
}

.fancy-rcb label,
.label-rcb {
    font-weight: 400;
    color: $default-color;
}
.label-rcb {
    display: inline-block;
}
label.label-inline.label-rcb {
    padding-top: 0;
}

.fancy-rcb input[type="radio"],
.fancy-rcb input[type="checkbox"] {
    display: none;
}
.fancy-rcb input[type="radio"] + span,
.fancy-rcb input[type="checkbox"] + span {
    display: inline-block;
    width: 21px;
    height: 21px;
    margin-top: -12px;
    margin-right: 4px;
    vertical-align: middle;
    cursor: pointer;
    font-family: FontAwesome;
    font-size: 21px;
    color: $default-color;
    font-weight: 400;
}
.fancy-rcb input[type="radio"] + span:before {
    content: '\f1db';
    -webkit-transition: .3s ease;
            transition: .3s ease;
}
.fancy-rcb input[type="radio"]:checked + span:before {
    content: '\f05d';
    color: #009707;
    -webkit-transition: .3s ease;
            transition: .3s ease;
}
.fancy-rcb input[type="checkbox"] + span:before {
    content: '\f096';
    -webkit-transition: .3s ease;
            transition: .3s ease;
}
.fancy-rcb input[type="checkbox"]:checked + span:before {
    content: '\f046';
    color: #009707;
    -webkit-transition: .3s ease;
            transition: .3s ease;
}

/*! Inputs */
input[type="text"],
input[type="password"],
input[type="file"],
textarea,
select,
input[type="tel"],
input[type="email"],
input[type="date"],
input[type="url"],
input[type="number"],
input[type="range"] {
	background-color: #fff;
	border: 1px solid #ccc;
	color: $default-color;
	padding: .375rem .625rem;
    border-radius: .25rem;
	box-shadow: none;
}
	input[type="text"]:focus,
	input[type="password"]:focus,
	textarea:focus,
	select:focus,
	input[type="tel"]:focus,
	input[type="email"]:focus,
	input[type="date"]:focus,
	input[type="url"]:focus,
	input[type="number"]:focus,
	input[type="range"]:focus {
		border-color: #999;
		outline: none;
		box-shadow: none;
    }
    /* Prefix/postfix search input focus border color to match non-focused */
    input.prefix-search[type="text"]:focus,
    input.postfix-search[type="text"]:focus {
        border-color: #ccc;
    }

input[type="text"] + input[type="text"] {
    margin-top: .5rem;
}
/* Input with button on left */
input.prefix {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* Input with button on right */
input.postfix {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

span.prefix,
span.postfix {
    display: inline-block;
    height: 36px; /* same as input height */
    line-height: 36px;
    vertical-align: top;
	background-color: #fff;
	border: 1px solid #ccc;
    color: #acacac;
}
/* span before input */
span.prefix {
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding-right: 0;
    padding-left: .5rem;
}
/* span after input */
span.postfix {
    border-left: none;
    border-radius: 0 4px 4px 0;
    padding-right: .5rem;
    padding-left: 0;
}

label.error {
	font-weight: 700;
    display: inline-block;
}
label label.error {
    margin-left: .25rem;
}

.instruction {
    font-size: 75%;
    font-weight: normal;
    color: #888;
}

/*! ---------------------------------------------------------------
BUTTONS
----------------------------------------------------------------*/
.button-group,
.button-group-inline {
	margin-top: 1.5rem;
}

.button {
    font-family: $secondary-font;
	background-color: $button-color;
	border: none;
	color: #fff;
	font-size: 1.25em;
	line-height: 2em;
	margin: 0;
	padding: 0 1.5em;
	text-transform: uppercase;
    transition: all .3s ease;
}
.button-ghost {
    background-color: transparent;
    border: 1px solid $button-color;
    color: $button-color;
}
.button-small {
    font-size: 85%;
}
.button-large {
    font-size: 1.5em;
}
.button:hover {
    background-color: $button-color-hover;
    color: #fff;
    text-decoration: none;
}
button[disabled],
.button[disabled],
input[disabled],
.button.disabled {
    opacity: .2;
}

/* Same as default button */
button[disabled]:hover,
.button[disabled]:hover,
.button.disabled:hover {
    background-color: $button-color;
    border: none;
    color: #fff;
}
.button-ghost:hover {
    background-color: transparent;
    border: 1px solid $button-color-hover;
    color: $button-color-hover;
}
/* Same as default button */
.button-ghost[disabled]:hover,
.button-ghost.disabled:hover {
    background-color: transparent;
    border: 1px solid $button-color-hover;
    color: $button-color-hover;
}

.button i {
    margin-right: .3em;
}

/* button before input */
.button.prefix,
.button.postfix {
    height: 36px; /* same as input height */
    line-height: 36px;
    vertical-align: top;
}
.button.prefix {
    border-right: none;
    border-radius: 4px 0 0 4px;
}
/* button after input */
.button.postfix {
    border-left: none;
    border-radius: 0 4px 4px 0;
}
.success-button {
    background: $success-color;
}

/*! ---------------------------------------------------------------
IMAGES
----------------------------------------------------------------*/
.image-frame {
	background-color: #fff;
	border: 1px solid $primary-border-color;
	padding: 3px;
}

figure {
	margin-bottom: 1.5rem;
    display: block;
}

figure img {
    margin: 0;
}

figcaption {
	font-size: .875em;
	font-style: italic;
	padding-top: .75em;
}

.thumb {
    display: inline-block;
    height: auto;
    line-height: 1.5rem;
}

/*! ---------------------------------------------------------------
HEADER
----------------------------------------------------------------*/
header {
	position: relative;
	width: 100%;
	z-index: 99;
    padding: 1rem;
    background-color: #eeebe6;
    border-bottom: 1px solid #d5cfc4;
}
#home header {
    background-color: transparent;
    border-bottom: none;
}
#logo {
	display: block;
    width: 140px;
	margin: 0;
	padding: 0;
}
#home #logo {
    width: auto;
}
.account-login {
    position: relative;
    padding: .5rem 1rem;
    background: $primary-bg-color;
    color: #fff;
    z-index: 999;
    text-align: right;
}
.account-login a.login-link {
    font-size: .75em;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    transition: all .4s ease;
}
.account-login a.login-link:hover {
    color:  #c9c2b2;
}
.social-links {
    display: inline-block;
    margin-right: 1rem;
}
.social-links a {
    color: #fff;
    margin-right: 4px;
    margin-left: 4px;
}
.social-links a:hover {
    color: #c9c2b2;
}

/*! ---------------------------------------------------------------
SECTIONS
----------------------------------------------------------------*/
section {
    margin-top: $baseline * 4;
    margin-bottom: $baseline * 4;
    position: relative;
}
.section-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
div:not(.column-right) + .section-content {
    right: 0;
}



/*! ---------------------------------------------------------------
NAVIGATION
----------------------------------------------------------------*/
@import 'navigation';

/*! ---------------------------------------------------------------
POSTINGS
----------------------------------------------------------------*/
@import 'postings';

/*! ---------------------------------------------------------------
CARDS
----------------------------------------------------------------*/
@import 'cards';

/*! ---------------------------------------------------------------
BREADCRUMB MENU
----------------------------------------------------------------*/
@import 'breadcrumb_menu';

/*! ---------------------------------------------------------------
PAGINATION
----------------------------------------------------------------*/
@import 'pagination';
/*! ---------------------------------------------------------------
PHOTO GALLERY
----------------------------------------------------------------*/
@import 'photo_gallery';

/*! ---------------------------------------------------------------
READ MORE/ALL
----------------------------------------------------------------*/
.read-all {
    margin-top: 1.5rem;
}
.read-more a,
.read-all a {
	font-weight: 700;
	text-decoration: none;
}
.read-more a:after {
	content: '\f061'; /* Right arrow */
	font-family: FontAwesome;
	padding-left: 4px;
    font-weight: normal;
}

/*! ---------------------------------------------------------------
VIDEO
----------------------------------------------------------------*/
.video-wrapper {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.float-left .video-wrapper,
.float-right .video-wrapper {
	margin-top: 0;
	margin-bottom: 0;
}

/*! ---------------------------------------------------------------
LOADING ANIMATION AND OVERLAY
----------------------------------------------------------------*/
#mask {
	background: #000;
    background: rgba(0,0,0,0.6);
}
#loading,
#confirm,
#please-wait,
#timeout-warning {
	padding: $baseline;
	background: #fff;
}
#confirm h3 {
    margin-top: 0;
}
#loading,
#please-wait {
	width: 300px;
    text-align: center;
    font-family: $secondary-font;
}
#loading span,
#please-wait span {
    font-size: 1.25em;
    color: $primary-color;
}
#timeout-warning {
	color: $critical-color;
	border: 2px solid $critical-color;
}
/* PLEASE WAIT ANIMATION */
.spinner > div{
    background-color: $primary-color;
}
.spinner {
  margin: 24px auto 0;
}
.spinner > div {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-left: 6px;
  background-color: #cd0606;
}

/*! ---------------------------------------------------------------
ALERTS
----------------------------------------------------------------*/
.alert-box h2,
.alert-box h3 {
	font-size: 1.5em;
	margin-top: 0;
}

/* ---------------------------------------------------------------
HOME PAGE
----------------------------------------------------------------*/
.hero-container {
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%;
    height: 600px;
    text-align: center;
}
.hero-container-content {
    position: absolute;
    bottom: 12%;
    left: 0;
    right: 0;
}
.hero-container h1 {
    font-size: 4em;
    line-height: 1em;
    color: #fff;
    text-shadow: 0 0 4px #555452;
    max-width: 1100px;
    margin: 0 auto;
    letter-spacing: normal;
}
/* ---------------------------------------------------------------
HOME PAGE MODEL CONTAINER
----------------------------------------------------------------*/
.models {
    position: relative;
    max-width: 1100px;
    margin: 390px auto 0;
    overflow: hidden;
    box-shadow: 0 1px 16px rgba(0,0,0,.7);
    background: #fff;
}
.model-container {
    position: relative;
    width: 25%;
    float: left;
}
.model-container a {
    display: block;
    max-height: 324px;
    margin: 8px 4px;
    overflow: hidden;
}
.model-container:first-of-type a {
    margin-left: 8px;
}
.model-container:last-of-type a {
    margin-right: 8px;
}
.model-container a img {
    transform: scale3d(1, 1, 1);
    transition: all .4s ease;
}
.model-container a:hover img {
    transform: scale3d(1.1, 1.1, 1);
}
.model-container-text {
    background: rgba(255,255,255,.4);
    text-align: center;
    font-family: $secondary-font;
    font-weight: 700;
    color: $primary-color;
    font-size: 1.25em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    transition: all .4s ease;
}

.model-container a:hover .model-container-text {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background: rgba(255,255,255,.8);
}

.home-copy {
    margin: 3rem auto;
    text-align: center;
    max-width: 960px;
}
.home-copy p {
    font-size: 1.5em;
    line-height: 1.5em;
}

.post-container {
    border: 1px solid #d5cfc4;
    background: #fff;
    padding: 1rem;
    margin: .5rem;
}
.post-container h2 {
    margin-top: 0;
    margin-bottom: .5em;
    padding-bottom: .25em;
    border-bottom: 1px solid #63625d;
}
.post-container h3 {
    margin-top: 0;
}
.flex-container {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}

/*! ---------------------------------------------------------------
TALENT PAGES
----------------------------------------------------------------*/
.title {
    margin-top: 0;
    padding-bottom: .25em;
    border-bottom: 1px solid $title-border-color;
}

.category-list {
    margin-left: 0;
    margin-bottom: 0;
    overflow: hidden;

    li {
        background-image: none;
        margin-left: 0;
        padding-left: 0;
    }
}

.headshots {
    display: flex;
    flex-wrap: wrap;
    margin-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
        position: relative;
        display: block;
        background-image: none;
        padding: 0 .5rem .5rem;
        margin: 0;
        text-align: center;
        overflow: hidden;

        .headshot-photo {
            position: relative;
            overflow: hidden;
            height: 150px;
            border: 1px solid $primary-border-color;

            @media screen and (min-width: 576px) {
                height: 160px;
            }

            @media screen and (min-width: 768px) {
                height: 140px;
            }

            @media screen and (min-width: 960px) {
                height: 180px;
            }

            @media screen and (min-width: 1200px) {
                height: 230px;
            }

            img {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                transition: all .3s ease;
            }
        }

        .headshot-name {
            font-family: $secondary-font;
            font-style: italic;
            text-align: center;
            padding: .5em;
        }
    }

    a {
        display: block;

        img {
            transform: translate(-50%,-50%) scale3d(1,1,1);
        }

        &:hover img {
            transform: translate(-50%,-50%) scale3d(1.1,1.1,1);
        }
    }
}

.non-client {
    cursor: not-allowed;
}
.non-client-notice {
    opacity: 0;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: .5em;
    background: rgba(0,0,0,.6);
    font-size: .8125em;
    color: #fff;
    text-align: center;
    font-weight: 700;
    z-index: 999;
    font-style: italic;
    transition: opacity .3s ease;
}
.non-client:hover .non-client-notice {
    opacity: 1;
}

.main-headshot {
    min-width: 100%;
    transform-style: preserve-3d;
}
.talent-details {
    margin-left: 0;

    li {
        background-image: none;
        padding-left: 0;
        margin-left: 0;
    }

    .talent-detail-label {
        color: $primary-color;
        font-weight: 700;
        display: inline-block;
        width: 30%;
        margin-right: 1rem;
    }
}
.talent-actions {
    display: inline-block;
    float: right;
    margin-left: 1rem;

    a {
        margin-left: .5rem;
    }
}

.talent-signup-photo {
    width: 20%;
    float: left;
    margin-right: $page-gutter;
}

/*! ---------------------------------------------------------------
ACCOUNTS
----------------------------------------------------------------*/
fieldset h2:first-of-type,
fieldset h3:first-of-type {
    margin-top: 0;
}
.info-group {
    margin-bottom: 1.5rem;
}

/*! ---------------------------------------------------------------
FOOTER
----------------------------------------------------------------*/
footer {
    margin-top: 3rem;
    padding: 2rem 0;
    background: #63625d;
    color: #fff;
}
footer a,
footer a:hover {
    color: #fff;
}
.footer-nav {
    margin: 0;
    list-style: none;
}
.footer-nav li {
    padding: 0;
    margin: 0;
    background-image: none;
}
.footer-nav a {
    font-size: .75em;
    color: #fff;
    text-decoration: none;
}
.footer-nav a:hover {
    text-decoration: underline;
}
address {
    font-size: .75em;
    text-align: center;
    font-style: normal;
    font-weight: 700;
}
.footer-logo {
    margin-bottom: .5rem;
}
.copyright {
    font-size: .6875em;
    text-align: center;
}

.alert-box p {
    font-weight: 700;
}

.hidden {
    display: none;
}

/*! ---------------------------------------------------------------
MEDIA QUERIES
----------------------------------------------------------------*/
@import 'media_queries';

/*! ---------------------------------------------------------------
PRINT
----------------------------------------------------------------*/
@media print {
    .account-login,
    .talent-actions {
        display: none;
    }

    h1,
    .h1 {
		font-size: $h2-size;
        margin-top: 0;
		margin-bottom: .5em;
	}
	h2,
    .h2 {
		font-size: $h3-size;
	}
	h3,
    .h3,
	aside h2 {
		font-size: $h4-size;
	}

    /* TALENT PAGE */

    .talent-page .column {
        display: inline-block;
        float: left;
        width: 48%;
    }
    .talent-page .talent-photos .column {
        width: 100%;
        float: none;
    }

}