/* ---------------------------------------------------------------
PAGINATION
----------------------------------------------------------------*/
.pagination {
	color: $default-color;
}
.pagination a {
	background-color: $secondary-bg-color;
	border: 1px solid $primary-border-color;
	color: $link-color;
	height: 2em;
	line-height: 2em;
	margin: 0 .5em 1em;
	padding: 0;
	width: 2em;
}
.pagination .current-page {
	height: 2em;
	line-height: 2em;
	margin: 0 .5em;
	padding: 0;
	width: 2em;
}
.pagination-count {
	margin-right: 1.5em;
}
.pagination-nav {
    float: none;
    margin: 2rem auto 0;
    display: block;
    text-align: center;
}
.pagination a:hover {
	background-color: $link-color;
	color: #fff;
    text-decoration: none;
}
.pagination .current-page {
	background-color: #fff;
	border: 1px solid $link-color;
	color: $link-color;
}

.prev-next-browsing {
	margin-bottom: 1.5em;
	text-align: center;
}
.prev-page:before {
	content: '\f0a8'; /* Left arrow */
	font-family: FontAwesome;
	padding-right: .3em;
}
.next-page:after {
	content: '\f0a9'; /* Right arrow */
	font-family: FontAwesome;
	padding-left: .3em;
}