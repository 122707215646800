/* ---------------------------------------------------------------
MEDIA QUERIES
----------------------------------------------------------------*/
$mobile-padding: 20px;

@media screen and (max-width: 960px) {
    /* PRIMARY NAVIGATION */
    #main-nav li .sub-menu {
        width: 12rem;
    }
}

@media screen and (max-width: 840px) {
    .row {
        max-width: 100%;
    }

    .hero-container {
        height: 480px;
    }
    .hero-container h1 {
        font-size: 3em;
        max-width: none;
    }
    .models {
        max-width: none;
        margin: 316px $mobile-padding 0;
    }
    .home-copy {
        max-width: 840px;
        padding: 0 $mobile-padding;
    }

    /* PRIMARY NAVIGATION */
    #main-nav li:hover .sub-menu,
    #main-nav .has-sub-menu:hover:after,
    #main-nav .has-sub-menu:hover:before {
        visibility: hidden; /* shows sub-menu */
        opacity: 0;
    }
    /* Talent Category menu */
    .mobile-sub-nav {
        color: $primary-color;
        float: right;
        font-size: 2rem;
        line-height: 1.25em;
    }
    #talent-category-nav {
        position: absolute;
        right: 20px;
        top: 88px;
        z-index: 99;

        ul {
            display: none;
            margin: 0;
            padding: 0;
            background: #eeebe6;
            border: 1px solid $primary-border-color;

            ul {
                display: inherit;
                border: none;
            }

            li {
                display: block;
                width: 100%;
                padding: 0;
                margin: 0;
                background-image: none;

                a {
                    display: block;
                    font-size: .9125em;
                    padding: .75em 1em;
                    line-height: 1em;
                    color: $primary-color;
                }
                a:hover {
                    text-decoration: none;
                }

                a.current-page {
                    color: $default-color;
                    font-style: italic;
                    font-weight: 700;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    #main-nav {
        margin-top: 0;
        text-align: center;
        background: $primary-color;
    }
    #main-nav > ul {
        position: absolute;
        width: 100%;
        height: auto;
        background: $primary-color;
        float: none;
    }

        #main-nav > ul li {
            padding: 0;
            display: inline-block;
            float: none;
        }

            #main-nav > ul li a {
                font-size: 1em;
                padding: .5em .75em;
                display: inline-block;
                color: #fff;
            }

                #main-nav > ul li a:hover {
                    color: #fff;
                }

    #home #main-nav li a:hover,
    #home #main-nav li:hover a {
        color: #fff;
        text-shadow: none;
    }
}

@media screen and (max-width: 640px) {
    .hide-med {
        display: none;
    }
    .show-med {
        display: block;
    }
    .card {
        height: auto;
    }
    header,
    header .span9 {
        padding-top: 0;
        padding-bottom: 0;
    }
    header,
    header .column {
        padding-right: 0;
        padding-left: 0;
    }
    #logo,
    #home #logo {
        width: 50%;
        margin: 8px auto;
    }
    #home #logo {
        width: 30%;
    }
    .hero-container {
        top: 174px;
    }
    .models {
        margin: 460px $mobile-padding 0;
    }
    .model-container {
        width: 50%;
    }
    .model-container a {
        max-height: 240px;
        margin: 4px;
    }

    .model-container:first-of-type a {
        margin-left: 4px;
    }
    .model-container:last-of-type a {
        margin-right: 4px;
    }
    .model-container:nth-child(odd) a {
        margin-right: 0;
    }

    .flex-container {
        display: block;
        padding-left: $mobile-padding;
        padding-right: $mobile-padding;
        margin-bottom: $mobile-padding;
    }
    .flex-column {
        display: block;
        width: 100%;
    }
    .post-container {
        margin: 0 0 $mobile-padding;
    }

    .talent-actions {
        display: block;
        float: none;
        margin-left: 0;
        margin-bottom: 1rem;
    }
    .main-headshot {
        margin: 0 auto;
    }


    /* TALENT */
    .headshots li .headshot {
        height: 150px;

        .headshot-name {
            font-size: .875em;
        }
    }



    /* FORMS */
    #searchForm .column {
        padding: 0;
    }
    #searchForm span[class*=span] {
        display: inline;
    }
    #searchForm label:not(.label-rcb) {
        text-align: left;
    }
    #searchForm .span1 input[type='text'] {
        max-width: 30%;
    }
    #searchForm .span1 select {
        max-width: 35%;
    }

    fieldset .column {
        padding-top: 0;
        padding-bottom: 0;
    }
    fieldset .row > .column:first-of-type {
        padding-top: 12px;
    }
    #dob span {
        max-width: 28%;
    }

    footer {
        margin-top: 1.5rem;
    }
    footer .span3,
    footer .span2 {
        float: left;
        width: 50%;
    }
    footer .span2 {
        width: 100%;
    }
    .footer-logo {
        max-width: 30%;
        margin: 1em auto .25em;
    }

    .button-group .button + .button {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .hide-sm {
        display: none;
    }
    .show-sm {
        display: block;
    }
    .float-left,
    .float-right {
        float: none;
        margin: 12px auto;
    }
    #home #logo {
        width: 45%;
    }

    .hero-container {
        top: 170px;
        height: 360px;
    }
    .hero-container-content {
        bottom: 8%;
    }
    .hero-container h1 {
        font-size: 2.25em;
        max-width: none;
    }
    .models {
        margin: 328px $mobile-padding 0;
    }
    .model-container {
        width: 50%;
    }
    .model-container a {
        max-height: 160px;
    }
    .model-container-text {
        font-size: 1em;
        padding: 1rem;
    }

    .model-container:first-of-type a {
        margin-left: 4px;
    }
    .model-container:last-of-type a {
        margin-right: 4px;
    }
    .model-container:nth-child(odd) a {
        margin-right: 0;
    }

    .home-copy p {
        font-size: 1em;
    }
    .home-copy .button-large {
        font-size: 1em;
    }

    .account-login {
        font-size: 1em;
    }
    .social-links {
        text-align: left;
        float: left;
        font-size: 1.2em;
    }

    #mobile-nav {
        display: block;
        position: relative;
        width: 100%;
        height: 40px;
        padding: .5rem 1rem;
        background: $primary-color;
    }
    .hamburger {
        display: inline-block;
        cursor: pointer;
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        overflow: visible;
        transition: opacity .15s linear;
    }

    .hamburger-box {
        width: 28px;
        height: 24px;
        display: inline-block;
        position: relative;
        float: left;
    }

    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: -2px;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        width: 28px;
        height: 3px;
        border-radius: 3px;
        background-color: #fff;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }
    .hamburger-inner::before, .hamburger-inner::after {
        content: "";
        display: block;
    }
    .hamburger-inner::before {
        top: -9px;
    }
    .hamburger-inner::after {
        bottom: -9px;
    }
    .hamburger-label {
        display: inline-block;
        font-weight: 700;
        color: #fff;
        margin-left: .5em;
    }
    /*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.1s 0.14s ease, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: bottom 0.1s ease, -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

    #main-nav {
        text-align: left;
    }
    #main-nav > ul {
        display: none;
        position: absolute;
        width: 100%;
        height: auto;
        background: $primary-color;
        float: none;
    }

  /* Display nav items vertically */
    #main-nav > ul li {
        display: block;
        float: none;
        width: 100%;
        padding: 0;
        background: #d5cfc4;
        border-bottom: 1px solid #65645f;
    }

    #main-nav > ul li a {
        display: block;
        font-size: 1em;
        padding: .75em 1em;
        line-height: 1.125em;
        color: $primary-color;
        text-align: left;
    }
    #main-nav > ul li a:hover {
        text-decoration: none;
    }
    #home #main-nav li a {
        color: $primary-color;
    }
    #main-nav .has-sub-menu:after,
    #main-nav .has-sub-menu:before {
        display: none;
    }

    #main-nav .sub-menu.sub-menu-100 {
        display: none;
    }

    #main-nav li .sub-menu,
    #main-nav li:hover .sub-menu {
        visibility: visible;
        opacity: 1;
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        height: auto;
        padding: 0;
        background-color: transparent;
        border: none;
        z-index: 1;
    }
    #main-nav li .sub-menu.sub-menu-100 {
        visibility: hidden;
    }
    #main-nav .sub-menu ul {
        float: none;
    }
    #main-nav .sub-menu li {
        float: none;
        display: block;
        padding: .75em 1.5em;
        background: #fff;
        border-top: 1px solid #d5cfc4;
        border-bottom: none;
    }
    h1,
    .h1 {
		font-size: $h2-size;
        margin-top: 0;
		margin-bottom: .5em;
	}
	h2,
    .h2 {
		font-size: $h3-size;
	}
	h3,
    .h3,
	aside h2 {
		font-size: $h4-size;
	}
	h4,
    .h4,
	aside h3 {
		font-size: $h5-size;
	}
	h5,
    .h5,
	aside h4 {
		font-size: $h6-size;
	}

    .button-group .button {
        margin-bottom: 1rem;
    }
}