/* ---------------------------------------------------------------
MAIN NAVIGATION MENU
----------------------------------------------------------------*/
#mobile-nav {
    display: none;
}
#main-nav {
    position: relative;
    margin-top: 16px;
}
#main-nav ul {
    float: right;
    margin: 0;
    padding: 0;
}
#main-nav li {
    background-image: none;
    display: block;
    position: relative;
    float: left;
    margin: 0;
    padding: .5em 1em;
}
#main-nav li a {
    font-family: 'Roboto Slab', serif;
    color: $link-color;
    font-size: .9125em;
    font-weight: 700;
    text-decoration: none;
    transition: all .4s ease;
}
#home #main-nav li a {
    color: #fff;
}
#main-nav li a:hover,
#main-nav li:hover a,
#home #main-nav li a:hover,
#home #main-nav li:hover a {
    color: $link-color-hover;
    text-shadow: 0 0 2px #fff;
}
#main-nav li .sub-menu {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    position: absolute;
    top: 37px;
    left: 0;
    width: 16rem;
    height: auto;
    padding: 1rem .75rem;
    background: rgba(255,255,255,1);
    border: 1px solid #63625d;
    transition: all .3s linear;
}
#main-nav .has-sub-menu:after,
#main-nav .has-sub-menu:before {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
	bottom: 0;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
    transition: all .3s linear;
}
#main-nav li:hover .sub-menu,
#main-nav .has-sub-menu:hover:after,
#main-nav .has-sub-menu:hover:before {
    visibility: visible; /* shows sub-menu */
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
}
#main-nav .sub-menu.sub-menu-100:after,
#main-nav .sub-menu.sub-menu-100:before {
	left: 50%;
}
#main-nav .has-sub-menu:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: rgba(255,255,255,1);
	border-width: 6px;
	margin-left: -6px;
}
#main-nav .has-sub-menu:before {
	border-color: rgba(194, 225, 245, 0);
	border-bottom-color: #63625d;
	border-width: 8px;
	margin-left: -8px;
}

#main-nav .sub-menu.sub-menu-100 {
    left: -21.75rem;
    width: 50rem;
}

#main-nav .sub-menu ul {
    width: 100%;
}
#main-nav .sub-menu.sub-menu-100 ul {
    width: 33%;
    float: left;
    padding-right: .25rem;
    padding-left: .25rem;
}
#main-nav .sub-menu li {
    float: none;
    padding: .25em;
}
#main-nav .sub-menu a,
#home #main-nav .sub-menu li a {
    color: #a30d0d;
    font-weight: 400;
    padding: 0;
    text-align: left;
}
#main-nav .sub-menu p {
    text-align: center;
}
#main-nav .sub-menu a:hover {
    text-decoration: underline;
}

/* ---------------------------------------------------------------
SECONDARY NAVIGATION MENU
----------------------------------------------------------------*/
.secondary-nav {
    background: #eeebe6;
    border: 1px solid #d5cfc4;
    padding: 1rem;

    ul {
        margin: 0;
        padding: 0;
    }
    ul ul {
        margin-left: 1rem;
    }
    li {
        margin: 0;
        padding: .5em 0;
        background-image: none;
    }
    a {
        font-size: .9125em;
    }
    a.current-page {
        color: $default-color;
        font-style: italic;
    }
    a.current-page:not(.parent-page):before {
        content: '\f054';
        display: inline-block;
        font-family: FontAwesome;
        margin-left: -1em;
        padding-right: .25em;
        color: $primary-color;
        font-style: normal;
    }

}
